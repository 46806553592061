const ribbon = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
    className="stroke-themeMint fill-themeMint mr-1"
  >
    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">
      <path d="M1155 2949 c-18 -5 -73 -54 -140 -122 -109 -113 -112 -115 -245 -180 -164 -79 -167 -83 -180 -235 -12 -132 -34 -200 -105 -317 -84 -140 -84 -161 -5 -295 68 -114 88 -173 101 -288 5 -53 12 -112 15 -130 8 -52 55 -97 142 -132 127 -52 162 -77 277 -195 132 -135 144 -140 277 -104 115 30 234 31 360 0 143 -34 153 -31 268 91 112 118 152 147 273 199 140 60 155 81 168 237 10 129 35 206 100 308 27 43 55 96 61 117 16 53 -1 114 -55 197 -74 113 -85 148 -97 294 -8 93 -13 115 -37 154 -30 49 -80 81 -178 114 -76 26 -137 71 -212 157 -96 110 -126 131 -182 131 -25 0 -83 -9 -129 -20 -110 -26 -204 -25 -332 5 -58 14 -107 24 -110 24 -3 0 -18 -5 -35 -10z m550 -293 c114 -39 208 -98 296 -185 87 -87 146 -181 186 -296 25 -73 27 -92 28 -230 0 -143 -1 -154 -31 -238 -40 -111 -83 -182 -162 -268 -142 -154 -333 -238 -540 -239 -124 0 -222 23 -340 81 -87 43 -116 63 -185 132 -152 152 -227 328 -227 537 0 360 264 668 630 734 14 3 79 3 145 2 99 -3 134 -8 200 -30z" />{" "}
      <path d="M1330 2504 c-199 -52 -364 -219 -415 -419 -19 -76 -19 -205 0 -280 54 -204 217 -367 422 -420 67 -18 190 -19 265 -4 195 40 381 218 433 415 19 70 21 210 5 279 -49 201 -221 379 -416 429 -76 20 -220 20 -294 0z m173 -233 c9 -27 35 -76 58 -110 62 -93 132 -145 279 -208 24 -10 24 -11 5 -18 -158 -56 -300 -189 -344 -323 -8 -24 -19 -41 -24 -39 -5 1 -20 29 -33 61 -29 71 -88 151 -146 199 -44 36 -161 97 -186 97 -29 0 -4 22 52 45 76 31 126 67 186 132 42 46 94 136 114 196 9 29 20 20 39 -32z" />{" "}
      <path d="M921 972 c-22 -41 -281 -644 -287 -667 -4 -17 -1 -27 9 -31 8 -3 89 15 179 40 151 43 164 45 176 29 6 -9 44 -79 83 -155 50 -96 76 -138 88 -138 21 0 23 5 225 465 l154 350 -65 3 c-42 2 -95 -5 -150 -19 -166 -41 -258 -18 -352 88 -46 51 -50 54 -60 35z" />{" "}
      <path d="M1974 936 c-30 -37 -59 -62 -98 -81 -52 -26 -64 -27 -144 -23 l-88 5 -54 -130 c-30 -72 -54 -135 -52 -141 11 -36 223 -511 232 -520 17 -17 44 22 122 177 55 110 72 137 86 133 91 -29 307 -86 322 -86 14 0 20 7 20 21 0 12 -61 161 -136 332 -75 172 -141 324 -146 340 -6 15 -13 27 -16 27 -4 0 -26 -24 -48 -54z" />{" "}
    </g>
  </svg>
);

const video = (
  <svg
    strokeWidth="0"
    viewBox="0 0 512 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-themeBlue fill-themeBlue mr-1"
  >
    <path d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"></path>
  </svg>
);

const pdf = (
  <svg
    strokeWidth="0"
    viewBox="0 0 384 512"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-themeBlue fill-themeBlue mr-1"
  >
    <path d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"></path>
  </svg>
);
const bib = (
  <svg
    strokeWidth="0"
    viewBox="0 0 16 16"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-themeBlue fill-themeBlue mr-1"
  >
    <path
      fill-rule="evenodd"
      d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
    ></path>
    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"></path>
  </svg>
);
const poster = (
  <svg
    fill="#000000"
    height="1em"
    width="1em"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-themeBlue fill-themeBlue mr-1"
  >
    <path d="M0 26.016q0 2.496 1.76 4.224t4.256 1.76h20q2.464 0 4.224-1.76t1.76-4.224v-20q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v20zM4 26.016v-20q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v20q0 0.832-0.576 1.408t-1.408 0.576h-20q-0.832 0-1.44-0.576t-0.576-1.408zM6.016 24q0 0.832 0.576 1.44t1.408 0.576h16q0.832 0 1.408-0.576t0.608-1.44v-0.928q-0.224-0.448-1.12-2.688t-1.6-3.584-1.28-2.112q-0.544-0.576-1.12-0.608t-1.152 0.384-1.152 1.12-1.184 1.568-1.152 1.696-1.152 1.6-1.088 1.184-1.088 0.448q-0.576 0-1.664-1.44-0.16-0.192-0.48-0.608-1.12-1.504-1.6-1.824-0.768-0.512-1.184 0.352-0.224 0.512-0.928 2.24t-1.056 2.56v0.64zM6.016 9.024q0 1.248 0.864 2.112t2.112 0.864 2.144-0.864 0.864-2.112-0.864-2.144-2.144-0.864-2.112 0.864-0.864 2.144z"></path>
  </svg>
);

export const icons = {
  ribbon,
  video,
  pdf,
  bib,
  poster,
};
